import FrontpageContent from "../components/page-templates/frontpage-content";

export default function Home({ page }) {
  return <FrontpageContent page={page} />;
}

export async function getStaticProps() {
  const res = await fetch(process.env.API_URL + `wp/v2/frontpage`);
  const page = await res.json();

  return {
    props: {
      page,
    },
    revalidate: 60,
  };
}
